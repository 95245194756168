<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder px-5">Add New Courier Account</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row">
          <div
            class="col-12 col-sm-6 py-0"
            :class="!serverData.organizations ? 'col-md-4' : ''"
          >
            <v-text-field
              v-model="formData.title"
              label="Title"
              clearable
              outlined
              dense
              :error-messages="titleErrors"
              @input="$v.formData.title.$touch()"
              @blur="$v.formData.title.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 py-0" v-if="serverData.organizations">
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              :error-messages="organization_idErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            ></v-autocomplete>
          </div>

          <div
            class="col-12 col-sm-6 py-0"
            :class="!serverData.organizations ? 'col-md-4' : ''"
          >
            <v-select
              v-model="formData.carrier_id"
              label="Carrier"
              item-text="name"
              item-value="id"
              :items="serverData.carriers"
              clearable
              outlined
              dense
              required
              :error-messages="carrier_idErrors"
              @input="$v.formData.carrier_id.$touch()"
              @blur="$v.formData.carrier_id.$touch()"
            ></v-select>
          </div>
          <div
            class="col-12 col-sm-6 py-0"
            :class="!serverData.organizations ? 'col-md-4' : ''"
          >
            <v-select
              v-model="formData.template_type"
              label="Template type"
              item-text="text"
              item-value="index"
              :items="serverData.template_types"
              clearable
              outlined
              dense
              required
              :error-messages="template_typeErrors"
              @input="$v.formData.template_type.$touch()"
              @blur="$v.formData.template_type.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 col-md-4 py-0">
            <v-select
              v-model="formData.label_qr_code_values"
              label="Label QR code values"
              item-text="text"
              item-value="index"
              :items="serverData.label_qr_code_values"
              outlined
              dense
              required
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0">
            <v-text-field
              v-model="formData.email"
              label="Email"
              clearable
              outlined
              dense
              :error-messages="emailErrors"
              @input="$v.formData.email.$touch()"
              @blur="$v.formData.email.$touch()"
            ></v-text-field>
          </div>

          <div class="col-12 py-0">
            <v-text-field
              v-model="formData.url"
              label="Url"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="text-center col-12" v-if="formData.template_type === 1">
            <h3>Label template</h3>
            <ckeditor v-model="formData.label_template"></ckeditor>
            <span class="text-danger text-left d-block px-3">{{
              label_templateErrors[0]
            }}</span>
          </div>
          <div
            v-if="formData.template_type === 1"
            class="col-12 col-sm-6 col-md-4 py-0"
          >
            <v-text-field
              v-model="formData.label_width"
              label="Label width"
              clearable
              type="Number"
              outlined
              dense
              :min="0"
              @change="() => validateMinValue('label_width', 0)"
              :error-messages="label_widthErrors"
              @input="$v.formData.label_width.$touch()"
              @blur="$v.formData.label_width.$touch()"
            ></v-text-field>
          </div>
          <div
            v-if="formData.template_type === 1"
            class="col-12 col-sm-6 col-md-4 py-0"
          >
            <v-text-field
              v-model="formData.label_height"
              label="Label height"
              clearable
              type="Number"
              outlined
              dense
              :min="0"
              @change="() => validateMinValue('label_height', 0)"
              :error-messages="label_heightErrors"
              @input="$v.formData.label_height.$touch()"
              @blur="$v.formData.label_height.$touch()"
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6 col-md-4 py-0">
            <v-text-field
              v-model="formData.delivery_no_prefix"
              label="Delivery No Prefix"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-12 text-center pb-4">
            <h3>Credentials</h3>
            <v-jsoneditor v-model="formData.credentials" :options="options" />
          </div>
        </div>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import VJsoneditor from "v-jsoneditor/src/index";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  mixins: [validationMixin, fieldValueValidation],
  components: { VJsoneditor },
  validations() {
    return this.formData.template_type === 1
      ? {
          formData: {
            carrier_id: { required },
            label_width: {
              required,
            },
            label_height: {
              required,
            },
            label_template: {
              required,
            },
            template_type: { required },
            title: { required },
            email: { email },
            organization_id: {
              required: requiredIf(() => {
                return !!this.serverData.organizations;
              }),
            },
          },
        }
      : {
          formData: {
            carrier_id: { required },
            template_type: { required },
            title: { required },
            email: { email },
            organization_id: {
              required: requiredIf(() => {
                return !!this.serverData.organizations;
              }),
            },
          },
        };
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    carrier: null,
    formData: {
      carrier_id: null,
      url: null,
      template_type: null,
      label_template: null,
      label_width: null,
      label_height: null,
      title: null,
      credentials: {},
      label_qr_code_values: "deliveryNo",
      organization_id: null,
      email: null,
    },
    options: { mode: "code", navigationBar: true, mainMenuBar: false },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/shipping/courierAccounts/create")
        .then((response) => {
          this.serverData = response.data;
          this.formData.label_template = this.serverData.template_default;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },

    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] != "image" && item[0] != "photo") ||
          item[1] == 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else if (
            typeof item[1] === "object" &&
            item[1] !== null &&
            !Array.isArray(item[1])
          ) {
            data.append(item[0], JSON.stringify(item[1]));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] == "image" || item[0] == "photo")) {
          // console.log(item[1], item[0], item[1][0]);
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },

    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.pageLoader(true);
      let data = this.convertToFormData();
      ApiService.post("/shipping/courierAccounts/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Courier Account has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        carrier_id: null,
        url: null,
        label_template: this.serverData.template_default,
        label_width: null,
        label_height: null,
        title: null,
        template_type: null,
        credentials: {},
        email: null,
        label_qr_code_values: "deliveryNo",
        organization_id: null,
      };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    carrier_idErrors() {
      return this.handleFormValidation("carrier_id", this);
    },
    template_typeErrors() {
      return this.handleFormValidation("template_type", this);
    },
    label_widthErrors() {
      return this.handleFormValidation("label_width", this);
    },
    label_heightErrors() {
      return this.handleFormValidation("label_height", this);
    },
    label_templateErrors() {
      return this.handleFormValidation("label_template", this);
    },
    titleErrors() {
      return this.handleFormValidation("title", this);
    },
    emailErrors() {
      return this.handleFormValidation("email", this);
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id", this);
    },
  },
  watch: {
    carrier: function (val) {
      this.formData.carrier_id = val;
      this.formData.label_template = this.serverData.carriers.filter(
        (car) => car.id == val
      )[0].labelTemplate;
      // console.log(val);
    },
  },
};
</script>
